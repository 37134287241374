<template>
  <Draggable :key="`draggable_page_${item.key}`">
    <div>
      <div
        class="flex group gap-1.5 select-none py-2 items-center cursor-pointer page-record hover:bg-gray-100 rounded-lg"
        :style="{ paddingLeft: level > 0 ? `${level * 1.7}rem` : '0.5rem' }"
        :class="{ 'bg-gray-100': activeKey === item.key }"
      >
        <!-- <div class="drag-handle top-2.5 opacity-50 group-hover:opacity-100">
          <fw-icon-draggable class="w-5 h-5" />
        </div> -->
        <div
          v-if="item.subpages?.length"
          class="h-8 w-8 flex items-center flex-shrink-0 justify-center hover:bg-gray-200 rounded cursor-pointer"
          @click.stop="expandChildren()"
        >
          <fw-icon-chevron-right
            class="h-5 w-5 transition-all"
            :class="{
              'transform rotate-90': expanded
            }"
          />
        </div>
        <div v-else class="h-8 w-8 flex-shrink-0" @click="$emit('open', item)"></div>
        <fw-icon-file-list class="h-5 w-5 text-gray-700 flex-shrink-0" @click.native="$emit('open', item)" />
        <div
          class="flex-1 flex gap-2 text-left items-center cursor-pointer ml-0.5"
          :class="{
            'font-normal': activeKey !== item.key,
            'font-semibold': activeKey === item.key
          }"
          @click="$emit('open', item)"
        >
          <v-clamp v-if="item.title" autoresize :max-lines="1" class="text-black">
            {{ item.title }}
          </v-clamp>
          <div v-else class="text-gray-400">{{ $t('no_title') }}</div>
        </div>
        <div v-if="showActions" class="flex gap-2 items-center opacity-0 group-hover:opacity-100">
          <fw-menu-more append-to-body size="xs">
            <b-dropdown-item paddingless aria-role="menuitem">
              <fw-button type="basic-action" custom-class="w-full" @click.native="$emit('edit', item)">
                {{ $t('editContent') }}
              </fw-button>
            </b-dropdown-item>
            <b-dropdown-item paddingless aria-role="menuitem">
              <fw-button type="basic-action" custom-class="w-full" @click.native="$emit('edit-document', item)">
                {{ $t('edit') }}
              </fw-button>
            </b-dropdown-item>
            <b-dropdown-item paddingless aria-role="menuitem">
              <fw-button type="basic-action" custom-class="w-full" @click.native="$emit('delete-document', item)">
                {{ $t('delete') }}
              </fw-button>
            </b-dropdown-item>
          </fw-menu-more>
          <fw-button-dropdown
            type="transparent"
            :expanded="false"
            size="xs"
            :chevron="false"
            @click.native="$emit('new-document', item)"
          >
            <div class="p-0.5">
              <fw-icon-add class="w-5 h-5" />
            </div>
          </fw-button-dropdown>
        </div>
      </div>

      <Container
        v-if="expanded && item.subpages?.length"
        :group-name="`subpages-of-${item.key}`"
        :lock-axis="'y'"
        :get-child-payload="
          index => {
            return { ...item.subpages[index], parent_key: item.key }
          }
        "
        drag-class="card-ghost"
        drop-class="card-ghost-drop"
        :drop-placeholder="{
          className: 'drop-preview',
          animationDuration: '150',
          showOnTop: true
        }"
        :should-accept-drop="() => true"
        @drop="$emit('drop', { parent: item, dropResult: $event })"
        @drag-start="$emit('drag-start', $event)"
        @drag-end="$emit('drag-end', $event)"
      >
        <PageRecordDraggable
          v-for="subpage in item.subpages"
          :key="`draggable_subpage_${subpage.key}`"
          :item="subpage"
          :users="users"
          :active-key="activeKey"
          :level="level + 1"
          :show-actions="showActions"
          v-on="$listeners"
        />
      </Container>
    </div>
  </Draggable>
</template>

<script>
import { Draggable, Container } from 'vue-dndrop'

export default {
  name: 'PageRecordDraggable',
  components: {
    Container,
    Draggable
  },
  props: {
    users: {
      type: Object,
      default: () => {}
    },
    showActions: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      required: true
    },
    activeKey: {
      type: String
    },
    level: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      expanded: false
    }
  },

  beforeMount() {
    if (this.isActiveInSubpages(this.item.subpages)) {
      this.expanded = true
    }
  },

  methods: {
    expandChildren() {
      this.expanded = !this.expanded
    },

    isActiveInSubpages(pages) {
      if (!pages?.length) return false
      if (pages.find(el => el.key === this.activeKey || this.isActiveInSubpages(el.subpages))) return true
      return false
    }

    // shouldAcceptDrop(sourceContainerOptions, payload) {
    //   console.log('shouldAcceptDrop', sourceContainerOptions, payload)
    //   return true
    // }
  }
}
</script>
<style scoped>
.page-record .item-arrow {
  @apply transition-all;
}
.page-record:hover .item-arrow {
  transform: translateX(3px);
}
.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  border-radius: 0.5rem;
  margin: 5px;
}

.card-ghost {
  transition: all 0.18s ease;
  opacity: 0.5;
}

.card-ghost-drop {
  opacity: 1;
}
</style>

<i18n lang="json">
{
  "pt": {
    "no_title": "Sem título",
    "created_at": "Criado em",
    "editContent": "Editar conteúdo",
    "delete": "Apagar",
    "edit": "Editar"
  },
  "en": {
    "no_title": "No title",
    "created_at": "Created at",
    "editContent": "Edit content",
    "delete": "Delete",
    "edit": "Edit"
  }
}
</i18n>
