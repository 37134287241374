var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Draggable',{key:`draggable_page_${_vm.item.key}`},[_c('div',[_c('div',{staticClass:"flex group gap-1.5 select-none py-2 items-center cursor-pointer page-record hover:bg-gray-100 rounded-lg",class:{ 'bg-gray-100': _vm.activeKey === _vm.item.key },style:({ paddingLeft: _vm.level > 0 ? `${_vm.level * 1.7}rem` : '0.5rem' })},[(_vm.item.subpages?.length)?_c('div',{staticClass:"h-8 w-8 flex items-center flex-shrink-0 justify-center hover:bg-gray-200 rounded cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.expandChildren()}}},[_c('fw-icon-chevron-right',{staticClass:"h-5 w-5 transition-all",class:{
            'transform rotate-90': _vm.expanded
          }})],1):_c('div',{staticClass:"h-8 w-8 flex-shrink-0",on:{"click":function($event){return _vm.$emit('open', _vm.item)}}}),_c('fw-icon-file-list',{staticClass:"h-5 w-5 text-gray-700 flex-shrink-0",nativeOn:{"click":function($event){return _vm.$emit('open', _vm.item)}}}),_c('div',{staticClass:"flex-1 flex gap-2 text-left items-center cursor-pointer ml-0.5",class:{
          'font-normal': _vm.activeKey !== _vm.item.key,
          'font-semibold': _vm.activeKey === _vm.item.key
        },on:{"click":function($event){return _vm.$emit('open', _vm.item)}}},[(_vm.item.title)?_c('v-clamp',{staticClass:"text-black",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_c('div',{staticClass:"text-gray-400"},[_vm._v(_vm._s(_vm.$t('no_title')))])],1),(_vm.showActions)?_c('div',{staticClass:"flex gap-2 items-center opacity-0 group-hover:opacity-100"},[_c('fw-menu-more',{attrs:{"append-to-body":"","size":"xs"}},[_c('b-dropdown-item',{attrs:{"paddingless":"","aria-role":"menuitem"}},[_c('fw-button',{attrs:{"type":"basic-action","custom-class":"w-full"},nativeOn:{"click":function($event){return _vm.$emit('edit', _vm.item)}}},[_vm._v(" "+_vm._s(_vm.$t('editContent'))+" ")])],1),_c('b-dropdown-item',{attrs:{"paddingless":"","aria-role":"menuitem"}},[_c('fw-button',{attrs:{"type":"basic-action","custom-class":"w-full"},nativeOn:{"click":function($event){return _vm.$emit('edit-document', _vm.item)}}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])],1),_c('b-dropdown-item',{attrs:{"paddingless":"","aria-role":"menuitem"}},[_c('fw-button',{attrs:{"type":"basic-action","custom-class":"w-full"},nativeOn:{"click":function($event){return _vm.$emit('delete-document', _vm.item)}}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])],1)],1),_c('fw-button-dropdown',{attrs:{"type":"transparent","expanded":false,"size":"xs","chevron":false},nativeOn:{"click":function($event){return _vm.$emit('new-document', _vm.item)}}},[_c('div',{staticClass:"p-0.5"},[_c('fw-icon-add',{staticClass:"w-5 h-5"})],1)])],1):_vm._e()],1),(_vm.expanded && _vm.item.subpages?.length)?_c('Container',{attrs:{"group-name":`subpages-of-${_vm.item.key}`,"lock-axis":'y',"get-child-payload":index => {
          return { ..._vm.item.subpages[index], parent_key: _vm.item.key }
        },"drag-class":"card-ghost","drop-class":"card-ghost-drop","drop-placeholder":{
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },"should-accept-drop":() => true},on:{"drop":function($event){return _vm.$emit('drop', { parent: _vm.item, dropResult: $event })},"drag-start":function($event){return _vm.$emit('drag-start', $event)},"drag-end":function($event){return _vm.$emit('drag-end', $event)}}},_vm._l((_vm.item.subpages),function(subpage){return _c('PageRecordDraggable',_vm._g({key:`draggable_subpage_${subpage.key}`,attrs:{"item":subpage,"users":_vm.users,"active-key":_vm.activeKey,"level":_vm.level + 1,"show-actions":_vm.showActions}},_vm.$listeners))}),1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }