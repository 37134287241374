<template>
  <SidebarList
    ref="pagesEl"
    :data-title="'Páginas'"
    data-key="pages"
    width="w-[25rem]"
    :api-call-function="getPages"
    :show-filters="false"
    :show-searchbar="true"
    :starts-open="true"
    :collapsable="collapsable"
    :no-data-label="'Sem páginas criadas'"
  >
    <template #pre-toolbar>
      <fw-button
        v-if="validations.can_create"
        type="light-primary"
        icon="add-line"
        size="sm"
        @click.native="$emit('new-document')"
      >
        Criar documento
      </fw-button>
      <fw-button v-if="validations.can_show_deleted" size="sm" @click.native="toggleDeleted">
        <fw-icon-trash class="w-5 h-5" />
      </fw-button>
    </template>

    <template #records="{ items, users }">
      <!-- drag-handle-selector=".drag-handle" -->
      <Container
        class="flex flex-col gap-1 relative"
        drag-class="card-ghost"
        drop-class="card-ghost-drop"
        :lock-axis="'y'"
        :get-child-payload="index => items[index]"
        :drop-placeholder="{
          className: 'drop-preview',
          animationDuration: '150',
          showOnTop: true
        }"
        group-name="pages"
        :should-accept-drop="() => true"
        @drop="handleOnDrop({ parent: null, level: 0, dropResult: $event })"
      >
        <PageRecordDraggable
          v-for="item in items"
          :key="item.key"
          :item="item"
          :users="users"
          :active-key="pageKey"
          :show-actions="showRecordActions"
          @drop="handleOnDrop($event)"
          @open="$emit('open-document', $event)"
          @edit="$emit('edit-content', $event)"
          @edit-document="$emit('edit-document', $event)"
          @new-document="$emit('new-document', $event)"
          @delete-document="confirmDeleteDocument($event)"
        ></PageRecordDraggable>
      </Container>
    </template>
  </SidebarList>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import SidebarList from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarList'
import PageRecordDraggable from '@/fw-modules/fw-core-vue/pages/components/records/PageRecordDraggable'
import ServicePages from '@/fw-modules/fw-core-vue/pages/services/ServicePages'
import { Container } from 'vue-dndrop'

export default {
  components: {
    SidebarList,
    PageRecordDraggable,
    Container
  },

  props: {
    pageKey: {
      type: String
    },
    context: {
      type: String
    },
    contextKey: {
      type: String
    },
    collapsable: {
      type: Boolean,
      default: false
    },
    injectPayload: {
      type: Object,
      default: () => {}
    },
    showRecordActions: {
      type: Boolean,
      default: true
    },
    validations: {
      type: Object,
      default: () => {
        return {
          can_create: true,
          can_show_deleted: true
        }
      }
    },
    getDocumentsEndpoint: {
      type: Function,
      default: null
    }
  },

  data() {
    return {
      // showNewPageModal: false,
      // selectedDocument: null,
    }
  },

  computed: {
    trash() {
      return this.$route.query.trash === 'true'
    }
  },

  methods: {
    async getPages(params) {
      if (this.getDocumentsEndpoint) {
        return await this.getDocumentsEndpoint({
          ...params,
          deleted: this.trash,
          context: this.context,
          context_key: this.contextKey,
          subpages: true,
          ...this.injectPayload
        })
      }

      return await ServicePages.getPages({
        ...params,
        deleted: this.trash,
        context: this.context,
        context_key: this.contextKey,
        subpages: true,
        ...this.injectPayload
      })
    },

    toggleDeleted() {
      const queryParams = { ...this.$route.query }
      if (this.trash) delete queryParams.trash
      else queryParams.trash = 'true'
      this.$router.push({ path: this.$route.path, query: queryParams })
      this.$refs.pagesEl.forceUpdate()
    },

    confirmDeleteDocument(page) {
      this.$buefy.dialog.confirm({
        title: this.$t('delete_document_title'),
        message: this.$t('delete_document_message'),
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: async () => {
          this.deleteDocument(page)
        }
      })
    },

    async deleteDocument(page) {
      this.deletingPage = true
      await utils.tryAndCatch(this, async () => {
        await ServicePages.deletePage(page.key)
        this.$refs.pagesEl.forceUpdate()
      })
      this.deletingPage = false
    },

    async changeDocsOrder(key, payload) {
      console.log('changeDocsOrder :>> ', payload)
      await utils.tryAndCatch(this, async () => {
        const res = await ServicePages.updatePageOrder(key, payload)
        console.log('updatePageOrder :>> ', res)
      })
    },

    async handleOnDrop(res) {
      const { parent, dropResult } = res
      const { removedIndex, addedIndex, payload } = dropResult
      if (removedIndex === null && addedIndex === null) return
      console.log('handleOnDrop')
      console.log('dropResult :>> ', dropResult)
      console.log('parent :>> ', parent?.key, parent?.title)

      this.$refs.pagesEl.updateItems(null, data => {
        return this.applyDrag(data, parent, dropResult)
      })

      if (addedIndex == null) return
      if (parent?.key != payload.parent_key) {
        console.log('change parent to', parent?.key)
        await this.changeDocsOrder(payload.key, { position: addedIndex + 1, parent_key: parent?.key ?? null })
      } else {
        console.log('no parent change')
        await this.changeDocsOrder(payload.key, { position: addedIndex + 1 })
      }
    },

    findByParentKey(array, key) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index]
        if (element.key == key) {
          return element
        }

        if (element.subpages?.length) {
          const result = this.findByParentKey(element.subpages, key)
          if (result) return result
        }
      }
    },

    applyDrag(arr, finalParent, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      console.log('finalParent :>> ', finalParent)
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      console.log('result :>> ', result)
      let itemToAdd = payload

      if (removedIndex !== null) {
        if (payload.parent_key) {
          const parent = this.findByParentKey(result, payload.parent_key)
          console.log('found parent :>> ', parent)
          parent.subpages.splice(removedIndex, 1)
        } else {
          result.splice(removedIndex, 1)
        }
      }

      if (addedIndex !== null) {
        if (finalParent) {
          finalParent.subpages.splice(addedIndex, 0, itemToAdd)
        } else {
          result.splice(addedIndex, 0, itemToAdd)
        }
      }

      return result
    },

    forceUpdate() {
      this.$refs.pagesEl.forceUpdate()
    }
  }
}
</script>

<style>
.drop-preview {
  background-color: rgba(150, 150, 200, 0.1);
  border: 1px dashed #abc;
  margin: 5px;
}

.card-ghost {
  transition: all 0.18s ease;
  opacity: 0.5;
}

.card-ghost-drop {
  opacity: 1;
}
</style>
